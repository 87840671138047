.header {
    background-color: $color_white;
    &__top {
        background: #EEEEEE;
        @include m-tablet(){
            display: none;
        }
    }
    &__top-row {
        display: flex;
        justify-content: flex-end;
        padding: 9px 0 8px;
    }
    &__top-logo {
        @include m-tablet(){
            max-width: 100px;
        }
    }
    &__bottom {
    }
    &__row {
        padding: 25px 0;
        display: flex;
        align-items: center;
        @include m-tablet(){
            padding: 18px 0;
        }
    }
    &__logo {
        @include m-tablet(){
            max-width: 80px;
        }
    }
    &__nav {
        margin-left: auto;
        display: flex;
        @include m-tablet(){
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 280px;
            box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
            background-color: #fff;
            z-index: 99;
            padding: 10px 20px;
            transform: translateX(-110%);
            transition: $trsn;
            &.active{
                transform: translateX(0);
            }
        }
    }
    &__nav-item {
        &:not(:last-child){
            margin-right: 20px;
            @include m-tablet(){
                margin-right: 15px;
            }
            @include m-tablet(){
                margin-right: 0;
            }
        }
        @include m-tablet(){
            padding: 10px 0;
            border-bottom: 1px solid #000;
        }
    }
    &__nav-link {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 300;
        border-bottom: 1px solid transparent;
        padding: 3px 0;
        @include m-tablet(){
            font-size: 14px;
        }
        @include m-tablet(){
            text-transform: initial;
            font-size: 16px;
            font-weight: normal;
            border: none;
        }
        &:hover{
            border-color: currentColor;
        }
    }
    &__mmenu{
        width: 40px;
        height: 40px;
        position: relative;
        margin-left: auto;
        display: none;
        @include m-tablet(){
            display: block;
            z-index: 100;
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            top: 10px;
            height: 3px;
            background: #3C3535;
            box-shadow: 0 10px 0 #3c3535, 0 20px 0 #3c3535;
        }
    }
    &__menu-bg{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        transition: opacity 0.5s ease, visibility 0.5s ease;
        background: rgba(254, 254, 254, 0.25);
        opacity: 0;
        overflow: hidden;
        z-index: -99;
        &.active{
            z-index: 98;
            opacity: 1;
        }
    }
}