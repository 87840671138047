.configurator {
    background: url('../img/style/configurator/wall-background.jpg') repeat;
    padding: 120px 0;
    @include m-tablet-wide(){
        padding: 90px 0;
    }
    @include m-tablet(){
        padding: 60px 0;
    }
    @include m-phone(){
        padding: 40px 0;
    }
    &__title {
        text-align: center;
        font-size: 32px;
        margin: 0;
        font-weight: bold;
        @include m-tablet(){
            font-size: 28px;
        }
        @include m-phone(){
            font-size: 24px;
        }
    }
    &__wrap {
        width: 100%;
        max-width: 551px;
        margin: 50px auto 0;
        position: relative;
        @include m-tablet(){
            max-width: 500px;
        }
        @include m-phone(){
            max-width: 290px;
        }
    }
    &__labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include m-phone(){
            display: none;
        }
    }
    &__label {
        font-weight: normal;
        font-size: 16px;
        white-space: nowrap;
        span{
            font-weight: bold;
        }
    }
    &__sliders {
        margin-top: 20px;
        position: relative;
        z-index: 2;
        @include m-phone(){
            margin-top: 60px;
        }
        &:before{
            content: '';
            width: 104%;
            height: 104%;
            background: url('../img/style/configurator/ombra-placca.png') no-repeat bottom right;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        .slick-track{
            height: 380px;
            @include m-tablet(){
                height: 345px;
            }
            @include m-phone(){
                height: 200px;
            }
        }
    }
    &__slider {
        z-index: 3;
        &_abs {
            width: 54%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
    &__slide {
        img{
            height: 380px;
            object-fit: cover;
            @include m-tablet(){
                height: 345px;
            }
            @include m-phone(){
                height: 200px;
            }
        }
    }
    &__pager {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;
        @include m-phone(){
            height: 280px;
            justify-content: space-between;
        }
        &_left{
            left: -90px;
            @include m-phone(){
                left: 10px;
            }
        }
        &_right{
            right: -90px;
            @include m-phone(){
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
        &_mobile{
            display: none;
            @include m-phone(){
                display: flex;
                right: 10px;
            }
        }
    }
    &__pager-top {
        transform: rotate(-90deg);
    }
    &__pager-text {
        text-align: center;
        font-size: 14px;
        line-height: 1.3;
        margin: 10px 0;
        @include m-phone(){
            display: none;
        }
    }
    &__pager-top,
    &__pager-bottom{
        cursor: pointer;
        transform: rotate(-90deg);
        svg{
            width: 50px;
            height: 50px;
            stroke: #acacac;
            transition: $trsn;
            @include m-phone(){
                stroke: $color_black;
            }
        }
        &:hover{
            svg{
                stroke: $color_text;
            }
        }
        &.slick-arrow{
            position: static;
            margin: 0;
        }
    }
    &__pager-bottom {
        transform: rotate(-90deg) scale(-1,1);
    }
    &__bottom {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @include m-phone(){
            flex-wrap: wrap;
            margin-top: 60px;
        }
    }
    &__price {
        color: $color_black;
        @include m-phone(){
            flex: 0 0 100%;
            text-align: center;
            font-size: 18px;
        }
        span{
            font-weight: normal;
        }
    }
    &__info{
        flex: 0 0 100%;
        margin-top: 20px;
        font-size: 14px;
        line-height: 1.3;
        p{
            margin-bottom: 0.5em;
            margin-top: 0;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        margin-left: 30px;
        font-size: 16px;
        font-weight: normal;
        cursor: pointer;
        @include m-phone(){
            margin-left: 15px;
            margin-top: 20px;
        }
        svg{
            width: 50px;
            height: 50px;
            fill: transparent;
            stroke: #acacac;
            margin-right: 15px;
            transition: $trsn;
            @include m-tablet(){
                width: 30px;
                height: 30px;
                stroke: #696969;
            }
        }
        span{
            border-bottom: 1px solid transparent;
            padding: 3px 0;
            transition: $trsn;
        }
        &:hover{
            svg{
                stroke: $color_text;
            }
            span{
                border-color: currentColor;
            }
        }
    }
}
