.smarthome {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 40px;
    @include m-tablet(){
        padding-top: 50px;
    }
    @include m-phone(){
        padding-top: 0px;
        padding-bottom: 30px;
        overflow: hidden;
    }
    &__img {
        position: absolute;
        width: 42.29vw;
        height: 66.4vh;
        right: 0;
        top: -20%;
        @include m-tablet-wide(){
            top: 20px;
        }
        @include m-phone(){
            position: static;
            width: initial;
            height: initial;
            margin-right: -20px;
        }
    }
    &__content {
        width: 100%;
        max-width: 660px;
        @include m-tablet-wide(){
            max-width: 600px;
        }
        @include m-tablet(){
            max-width: 400px;
        }
    }
    &__title {
        margin: 0;
        font-weight: bold;
        font-size: 32px;
        line-height: 1.2;
        @include m-tablet-wide(){
            font-size: 28px;
        }
        @include m-phone(){
            font-size: 24px;
        }
    }
    &__text {
        margin-top: 32px;
        line-height: 1.5;
        @include m-tablet-wide(){
            margin-top: 25px;
        }
    }
    &__list {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin-top: 85px;
        padding: 0 40px;
        @include m-tablet-wide(){
            margin-top: 60px;
            padding: 0;
        }
        @include m-phone(){
            margin-top: 30px;
        }
    }
    &__item {
        @include flex-item(2,98px);
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        @include m-tablet(){
            @include flex-item(2,40px);
        }
        @include m-phone(){
            @include flex-item(1,20px);
        }
        &_center{
            .smarthome__desc{
                padding-top: 10px;
                @include m-phone(){
                    padding-top: 0;
                }
            }
        }
    }
    &__ico {
        flex: 0 0 72px;
        margin-right: 40px;
        @include m-tablet(){
            flex: 0 0 52px;
            margin-right: 30px;
        }
        svg{
            width: 72px;
            height: 72px;
            @include m-tablet(){
                width: 52px;
                height: 52px;
            }
        }
    }
    &__desc {
        flex: 1 1 100%;
        line-height: 1.5;
    }
}