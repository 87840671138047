.collection {
    padding-bottom: 100px;
    @include m-tablet(){
        padding-bottom: 60px;
    }
    &__top {
    }
    &__content {
        width: 100%;
        position: relative;
    }
    &__content-wrap{
        max-width: 874px;
        margin: 50px auto;
        @include m-tablet(){
            margin: 20px auto;
        }
    }
    &__title {
        font-size: 32px;
        font-weight: bold;
        margin: 0;
        text-align: center;
        @include m-tablet-wide(){
            font-size: 28px;
        }
        @include m-tablet(){
            font-size: 26px;
        }
        @include m-phone(){
            font-size: 24px;
            padding: 0 20px;
        }
    }
    &__text {
        line-height: 1.5;
        margin-top: 32px;
        text-align: center;
        padding: 0 50px;
        @include m-phone(){
            padding: 0 35px;
            margin-top: 24px;
        }
    }
    &__pagination {
        width: 100%;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        pointer-events: none;
    }
    &__pagination-arrow {
        cursor: pointer;
        pointer-events: all;
        svg{
            stroke: #9D9D9D;
            width: 50px;
            height: 50px;
            transition: $trsn;
        }
        &.prev{
            transform: scale(-1,1);
        }
        &:hover{
            svg{
                stroke: $color_text;
            }
        }

    }
    &__list {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        &_6{
            .collection{
                &__item {
                    @include flex-item(6,4px);
                    padding: 40px 23px;
                    @include m-tablet(){
                        @include flex-item(3,4px);
                        padding: 23px;
                    }
                    @include m-phone(){
                        @include flex-item(2,1px);
                        padding: 25px;
                    }
                }
            }
        }
    }
    &__item {
        @include flex-item(5,4px);
        padding: 50px 28px;
        @include m-tablet-wide(){
            padding: 40px 23px;
        }
        @include m-tablet(){
            @include flex-item(3,4px);
            padding: 23px;
        }
        @include m-phone(){
            @include flex-item(2,1px);
            padding: 25px;
        }
    }
    &__item-img {
        img{
            width: 100%;
        }
    }
    &__item-name {
        margin-top: 24px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        @include m-tablet-wide(){
            line-height: 1.3;
        }
    }
    &__item-desc {
        margin-top: 16px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: normal;
        @include m-tablet-wide(){
            font-size: 15px;
            line-height: 1.3;
        }
        @include m-phone(){
            font-size: 16px;
        }
    }
    .slick-dots{
        margin-top: 0;
    }
    .slick-arrow{
        margin-top: 0;
        top: 520px;
        @include m-tablet-wide(){
            top: 430px;
        }
        @include m-tablet(){
            top: 284px;
        }
        @include m-phone(){
            top: 240px;
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        flex-wrap: wrap;
        @include m-tablet(){
            margin-top: 30px;
        }
        .button{
            @include m-tablet(){
                font-size: 12px;
            }
            @include m-phone(){
                width: 100%;
            }
            &:not(:last-child){
                margin-right: 30px;
                @include m-phone(){
                    margin-right: 0px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}