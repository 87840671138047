@import "main-bx";
* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
}

body {
    font-family: $font_base;
    color: $color_text;
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    overflow-y: scroll;
    @include m-tablet-wide(){
        font-size: 18px;
    }
    @include m-tablet(){
        font-size: 16px;
    }
}
.over {
    overflow: hidden;
}
.container {
    width: 100%;
    max-width: 1350px; 
    padding: 0 15px;
    margin: 0 auto;
    @include m-tablet-wide() {
        max-width: 1024px;
    }
    @include m-tablet() {
        max-width: 768px;
    }
    @include m-phone() {
        max-width: 375px;
        padding: 0 15px;
    }
}

img{
    display: block;
}

p {
    margin: 10px 0 15px;
    &:first-child {
        margin-top: 0;
    }
}

a {
    outline: none;
    color: currentColor; 
    text-decoration: none;
    transition: $trsn;

    &:hover, &:focus {
        outline: none;
        text-decoration: none;
    }
}

[data-fancybox] {
    cursor: pointer;
}

img {
    max-width: 100%;
}

.wrapper {
    width: 100%;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    font-family: $font_second;
    line-height: 1.23;
    font-weight: bold;
}