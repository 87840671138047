.devices {
    padding: 100px 0;
    @include m-tablet-wide(){
        padding: 80px 0;
    }
    @include m-tablet(){
        padding: 50px 0;
    }
    @include m-phone(){
        padding: 40px 0;
    }
    &__title {
        margin: 0;
        font-family: $font_second;
        font-size: 55px;
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;
        @include m-tablet-wide(){
            font-size: 45px;
        }
        @include m-tablet(){
            font-size: 35px;
        }
    }
    &__subtitle {
        font-size: 32px;
        line-height: 1.15;
        margin-top: 32px;
        font-weight: bold;
        @include m-tablet-wide(){
            font-size: 28px;
            margin-top: 25px;
        }
        @include m-tablet(){
            font-size: 24px;
            margin-top: 20px;
        }
        @include m-phone(){
            margin-top: 25px;
        }
    }
    &__text {
        line-height: 1.5;
        margin-top: 32px;
        @include m-tablet-wide(){
            margin-top: 25px;
        }
    }
    &__slider {
        width: 874px;
        margin: 50px auto 0;
        @include m-tablet(){
            width: 700px;
            margin-top: 30px;
        }
        @include m-phone(){
            width: 100%;
        }
        .slick-track{
            display: flex;
            align-items: stretch;
        }
    }
    &__slide {
        display: flex!important;
        align-items: center;
        justify-content: center;
        height: auto;
        @include m-tablet(){
            padding: 0 50px;
        }
        @include m-phone(){
            width: 100%;
        }
    }
    &__slide-wrap {
    }
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        flex-wrap: wrap;
        @include m-tablet(){
            margin-top: 30px;
        }
        .button{
            @include m-phone(){
                width: 100%;
            }
            &:not(:last-child){
                margin-right: 30px;
                @include m-phone(){
                    margin-right: 0px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}