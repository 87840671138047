@import "modules/header/header";
@import "modules/imgblock/imgblock";
@import "modules/button/button";
@import "modules/devices/devices";
@import "modules/slider/slick";
@import "modules/smarthome/smarthome";
@import "modules/apps/apps";
@import "modules/scenari/scenari";
@import "modules/installation/installation";
@import "modules/configurator/configurator";
@import "modules/collection/collection";
@import "modules/guide/guide";
@import "modules/modal/modal";
