.apps {
    padding: 120px 0;
    @include m-tablet-wide(){
        padding: 80px 0;
    }
    @include m-tablet(){
        padding: 50px 0;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        @include m-phone(){
            flex-wrap: wrap;
        }
    }
    &__left {
        flex: 0 0 540px;
        @include m-tablet-wide(){
            flex: 0 0 400px;
        }
        @include m-tablet(){
            flex: 0 0 300px;
        }
        @include m-phone(){
            flex: 0 0 100%;
        }
    }
    &__img{
        margin-bottom: 30px;
    }
    &__title {
        margin: 0;
        font-family: $font_second;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;
        @include m-tablet-wide(){
            font-size: 28px;
        }
        @include m-tablet(){
            font-size: 26px;
        }
        @include m-phone(){
            font-size: 24px;
        }
    }
    &__text {
        line-height: 1.5;
        margin: 32px 0 20px;
        @include m-tablet-wide(){
            margin: 25px 0 20px;
        }
        @include m-tablet(){
            margin: 20px 0;
        }
        @include m-phone(){
            margin: 24px 0 0;
        }
    }
    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__link {
        margin-right: 20px;
        margin-top: 20px;
        &:hover{
            transform: scale(0.95);
        }
        img{
            @include m-tablet-wide(){
                max-height: 50px;
            }
            @include m-tablet(){
                max-height: 40px;
            }
            @include m-phone(){
                max-height: 36px;
            }
        }
    }
    &__right {
        flex: 0 0 640px;
        @include m-tablet-wide(){
            flex: 0 0 500px
        }
        @include m-tablet(){
            flex: 0 0 390px;
        }
        @include m-phone(){
            flex: 0 0 100%;
            margin-top: 30px;
        }
    }
}