.slick{
    &-arrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -32px;
        cursor: pointer;
        z-index: 9;
        @include m-phone(){
            margin-top: -15px;
        }
        &.prev{
            left: 0;
        }
        &.next{
            right: 0;
        }
        svg{
            width: 50px;
            height: 50px;
            stroke: $color_text;
            @include m-phone(){
                width: 30px;
                height: 30px;
            }
        }
    }
    &-dots{
        list-style: none;
        margin: 40px 0 0;
        padding: 0;
        display: flex;
        justify-content: center;
        @include m-tablet(){
            margin-top: 20px;
        }
        @include m-phone(){
            margin-top: 10px;
        }
        li{
            margin: 0 7px;
            button{
                width: 12px;
                height: 12px;
                border: none;
                outline: none;
                font-size: 0;
                position: relative;
                background: none;
                cursor: pointer;
                &:before{
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: rgba($color: #9D9D9D, $alpha: .5);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: $trsn;
                }
            }
            &.slick-active{
                button{
                    &:before{
                        width: 12px;
                        height: 12px;
                        background: $color_text;
                    }
                }
            }
            &:hover{
                button{
                    &:before{
                        background: $color_text;
                    }
                }
            }
        }
    }
}