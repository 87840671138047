.button{
    display: inline-block;
    border: 1px solid $color_text;
    padding: 15px 24px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: $color_text;
    outline: none;
    transition: $trsn;
    @include m-tablet(){
        font-size: 14px;
    }
    &:hover{
        box-shadow: inset 0 0 0 2px $color_text;
    }
    &_white{
        border-color: $color_white;
        color: $color_white;
        &:hover{
            box-shadow: inset 0 0 0 2px $color_white;
        }
    }
}