$default-font-color: #000000;


$font_base: 'Ubuntu', sans-serif;
$font_second: 'Montserrat', sans-serif;

//цвета
$color_body: #fff;
$color_text: #3C3535;

$color_white: #fff;
$color_black: #000;



$color_red: #f00;

//
$trsn: 0.2s;