.modal {
    width: 100%;
    max-width: 560px;
    background-color: #fff;
    padding: 80px 100px;
    position: relative;
    display: none;
    @include m-tablet(){
        padding: 60px;
        max-width: 460px;
    }
    @include m-phone(){
        padding: 70px 25px 20px;
        max-width: 360px;
    }
    .fancybox-close-small{
        display: none;
    }
    &__close{
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        transition: $trsn;
        @include m-tablet(){
            top: 20px;
            right: 20px;
        }
        svg{
            width: 35px;
            height: 35px;
        }
        &:hover{
            opacity: 0.5;
        }
    }
    &__title {
        text-align: center;
        font-size: 28px;
        line-height: 1.1;
        font-weight: bold;
        @include m-tablet(){
            font-size: 26px;
        }
        @include m-phone(){
            font-size: 20px;
        }
    }
    &__form {
        margin-top: 40px;
    }
    &__form-row {
        position: relative;
        margin-top: 16px;
        &_mt{
            margin-top: 24px;
        }
        &_button{
            margin-top: 40px;
        }
    }
    &__form-input {
        display: block;
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        padding: 0 15px;
        outline: none;
        font-size: 16px;
        font-family: $font_base;
        &.hasValue ~ ,
        &:focus ~{
            .modal{
                &__form-placeholder {
                    opacity: 0;
                }
            }
        }
    }
    &__form-placeholder {
        position: absolute;
        top: 15px;
        left: 0px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        pointer-events: none;
        transition: $trsn;
        .red{
            color: red;
        }
    }
    &__form-textarea {
        display: block;
        width: 100%;
        height: 120px;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        padding: 15px;
        outline: none;
        font-size: 16px;
        font-family: $font_base;
        resize: none;
        &:placeholder-shown{
            text-align: center;
        }
    }
    &__form-checkbox {
        font-size: 16px;
        cursor: pointer;
        display: block;
        min-height: 24px;
        input{
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
            &:checked ~ span{
                &:after{
                    opacity: 1;
                }
            }
        }
        span{
            position: relative;
            display: block;
            padding-left: 34px;
            &:before{
                content: '';
                width: 22px;
                height: 22px;
                border: 1px solid #CACACA;
                position: absolute;
                top: -2px;
                left: 0;
            }
            &:after{
                content: '';
                width: 24px;
                height: 24px;
                position: absolute;
                top: -2px;
                left: 0;
                background: #3C3535 url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 10l4.706 5L17 3' stroke='%23fff' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center center;
                opacity: 0;
                transition: $trsn;
            }
        }
    }
    .button {
        width: 100%;
        background-color: #fff;
    }
}