.installation {
    padding: 80px 0;
    @include m-tablet(){
        padding: 60px 0;
    } 
    &__title {
        text-align: center;
        margin: 0;
        font-size: 32px;
        font-weight: bold;
        @include m-tablet-wide(){
            font-size: 28px;
        }
        @include m-tablet(){
            font-size: 26px;
        } 
        @include m-phone(){
            font-size: 24px;
        } 
    }
    &__subtitle {
        text-align: center;
        width: 100%;
        max-width: 875px;
        margin: 32px auto 0;  
        @include m-tablet-wide(){
            margin-top: 25px;
        }  
        @include m-tablet(){
            margin-top: 20px;
        } 
    }
    &__sliders {
        display: flex;
        justify-content: space-between;
        margin: 50px 0 100px;
        @include m-tablet(){
            margin-bottom: 60px;
        } 
        @include m-phone(){
            flex-wrap: wrap;
        } 
    }
    &__sliders-col {
        flex: 0 0 calc(50% - 10px);
        min-width: 0;
        position: relative;
        @include m-phone(){
            flex: 0 0 100%;
        } 
        &:not(:first-child){
            @include m-phone(){
                margin-top: 40px;
            } 
            .installation{
                &__slider{
                    &:before{
                        content: '';
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: -10px;
                        background-color: #D9DFE4;
                        @include m-phone(){
                            display: none;
                        } 
                    }
                }
            }
        }
    }
    &__sliders-title {
        text-align: center;
        font-weight: bold;
        @include m-phone(){
            font-size: 18px;
        } 
    }
    &__slider {
        margin-top: 26px;
        .slick-track{
            display: flex;
            align-items: stretch;
            min-height: 303px;
        }
        .slick-arrow{
            margin-top: 0;
            @include m-phone(){
                margin-top: 10px;
            } 
        }
    }
    &__slide {
        height: auto;
        display: flex!important;
        flex-direction: column;
    }
    &__slide-text {
        text-align: center;
    }
    &__slide-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 30px;
        @include m-tablet-wide(){
            padding: 0 50px;
        } 
        @include m-phone(){
            padding: 0 35px;
        } 
    }
    &__boxes-title {
        text-align: center;
        margin-top: 50px;
        font-weight: bold;
        @include m-tablet(){
            font-size: 18px;
            margin-top: 40px;
        } 
        @include m-phone(){
            margin-top: 30px;
        } 
    }
    &__boxes-subtitle {
        text-align: center;
        font-size: 16px;
        margin-top: 10px;
    }
    &__boxes {
        border-top: 1px solid rgba($color: #3C3535, $alpha: 0.05);
        display: flex;
        margin-top: 24px;
        padding: 30px 0 20px;
        @include m-phone(){
            flex-wrap: wrap;
            margin-top: 16px;
            padding: 20px 0;
        } 
    }
    &__box {
        flex: 1;
        text-align: center;
        @include m-phone(){
            flex: 0 0 100%;
            &:not(:first-child){
                margin-top: 40px;
            }
        } 
    }
    &__box-image {
        img{
            margin: 0 auto;
            @include m-tablet-wide(){
                max-height: 230px;
            } 
            @include m-tablet(){
                max-height: 180px;
            } 
        }
    }
    &__box-title {
        font-size: 23px;
        line-height: 1.3;
        font-weight: bold;
        margin-top: 35px;
        @include m-tablet-wide(){
            font-size: 18px;
        } 
        @include m-tablet(){
            font-size: 16px;
        } 
        @include m-phone(){
            max-width: 260px;
            margin: 30px auto 0;
        } 
    }
}