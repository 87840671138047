.guide {
    padding: 85px 0;
    background-color: #000;
    @include m-tablet-wide(){
        padding: 60px 0;
    }
    @include m-tablet(){
        padding: 40px 0;
    }
    @include m-phone(){
        padding: 30px 0;
    }
    &__title {
        text-align: center;
        margin: 0;
        font-size: 32px;
        color: $color_white;
        font-weight: bold;
        @include m-tablet-wide(){
            font-size: 28px;
        }
        @include m-tablet(){
            font-size: 26px;
        }
        @include m-phone(){
            font-size: 24px;
        }
    }
    &__slide {
    }
    &__tab {
        display: none;
        &.active{
            display: block;
        }
    }
    &__row {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-top: 40px;
        @include m-tablet(){
            display: block;
        }
    }
    &__image {
        flex: 0 0 538px;
        position: relative;
        @include m-tablet-wide(){
            flex: 0 0 500px;
        }
        @include m-tablet(){
            width: 538px;
        }
        @include m-phone(){
            width: 100%;
            overflow: hidden;
        }
    }
    &__image-lbl {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 60px;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
        color: #3C3535;
        opacity: 0.2;
        @include m-tablet(){
            color: #ffffff;
            opacity: 0.1;
        }
        @include m-phone(){
            font-size: 30px;
            opacity: 0.08;
        }
    }
    &__slide-img {
        position: relative;
        @include m-phone(){
            width: calc(100% + 80px);
            margin-left: -40px;
            margin-top: 40px;
        }
    }
    &__tab-buttons {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        @include m-tablet(){
            margin: 35px 0;
        }
        @include m-phone(){
            margin-top: 25px;
        }
        .button{
            font-size: 12px;
            padding: 10px 20px;
            width: 230px;
            margin: 0 20px;
            border-color: #3C3535;
            color: #3C3535;
            user-select: none;
            @include m-phone(){
                padding: 10px;
                line-height: 1.4;
                margin: 0 10px;
            }
            &.active,
            &:hover{
                box-shadow: none;
                color: $color_white;
                border-color: $color_white;
            }
        }
    }
    &__desc {
        flex: 0 0 460px;
        padding-top: 15px;
        margin-left: 50px;
        color: $color_white;
        @include m-tablet-wide(){
            flex: 0 0 400px;
        }
        @include m-tablet(){
            margin-left: 0;
            margin-top: -60px;
        }
    }
    &__slide-title {
        font-size: 20px;
        font-weight: bold;
    }
    &__slide-text {
        font-size: 14px;
        line-height: 1.5;
        ul{
            margin: 1em 0;
            padding: 0;
            padding-left: 15px;
            li{
            }
        }
    }
    &__slide-lbl {
        font-size: 16px;
        text-transform: uppercase;
        opacity: 0.2;
        margin-top: 40px;
        font-weight: normal;
        @include m-phone(){
            margin-top: 20px;
        }
    }
    &__video-wrap{
        color: $color_white;
        @include m-phone(){
            margin-top: 30px;
        }
    }
    &__video-list{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid rgba($color: #fff, $alpha: .05);
        padding-top: 24px;
        margin-top: 24px;
    }
    &__video {
        position: relative;
        @include flex-item(4,30px);
        @include m-phone(){
            @include flex-item(2,15px);            
        }
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: $trsn;
        }
        img{
            width: 100%;
            height: 160px;
            object-fit: cover;
            @include m-tablet(){
                height: 120px;
            }
        }
        &:hover{
            &:before{
                opacity: 0.4;
            }
        }
    }
    &__video-pay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        svg{
            width: 40px;
            height: 40px;
            @include m-tablet(){
                width: 30px;
                height: 30px;
            }
        }
    }
    &__tooltip{
        width: 25px;
        height: 25px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 0%;
        left: 0%;
        cursor: pointer;
        &:before{
            content: '';
            width: 12px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            top: 11px;
            left: 6px;
        }
        &:after{
            content: '';
            width: 1px;
            height: 12px;
            background-color: #fff;
            position: absolute;
            top: 6px;
            left: 11px;
            transition: $trsn;
        }
        &:hover{
            .guide{
                &__tooltip-text{
                    opacity: 1;
                }
            }
            &:after{
                transform: rotate(90deg);
                opacity: 0;
            }
        }

    }
    &__tooltip-text{
        position: absolute;
        left: 35px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        line-height: 1.1;
        white-space: nowrap;
        color: #fff;
        opacity: 0;
        transition: $trsn;
        pointer-events: none;
        @include m-phone(){
            opacity: 1;
            font-size: 10px;
            width: 100px;
            white-space: normal;
            br{
                display: none;
            }
        }
    }
}
.container {
}
.button {
    &_white {
    }
}

.guide {
}
