.imgblock {
    position: relative;
    &__bg {
        max-width: 1920px;
        margin: 0 auto;
        img{
            height: 600px;
            object-fit: cover;
            @include m-tablet-wide(){
                height: 500px;
            }
            @include m-tablet(){
                height: 420px;
            }
            @include m-phone(){
                height: 300px;
            }
        }
        &_big{
            img{
                height: 660px;
                @include m-tablet-wide(){
                    height: 500px;
                }
            }
        }
        &_1{
            img{
                @include m-phone(){
                    object-position: -160px 0;
                }
            }
        }
        &_2{
            background: #a79f97;
            img{
                @include m-phone(){
                    object-position: -490px 0;
                }
            }
        }
    }
    &__content {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @include m-phone(){
            position: static;
            transform: none;
        }
        &_smart-home{
            transform: none;
            top: 115px;
            @include m-tablet-wide(){
                top: 60px;
            }
        }
    }
    &__wrap {
        display: flex;
        &_right{
            justify-content: flex-end;
        }
    }
    &__block {
        width: 600px;
        color: $color_white;
        @include m-tablet-wide(){
            width: 500px;
        }
        @include m-tablet(){
            width: 400px;
        }
        @include m-phone(){
            color: $color_text;
            padding: 30px 0;
        }
        &_black{
            color: $color_text;
        }
    }
    &__title {
        font-family: $font_second;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 55px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        @include m-tablet-wide(){
            font-size: 45px;
        }
        @include m-tablet(){
            font-size: 40px;
        }
        @include m-phone(){
            font-size: 35px;
            text-shadow: none;
        }
    }
    &__subtitle {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.15;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        margin-top: 32px;
        @include m-tablet-wide(){
            font-size: 26px;
            margin-top: 25px;
        }
        @include m-tablet(){
            font-size: 20px;
            margin-top: 20px;
        }
        @include m-phone(){
            font-size: 24px;
            text-shadow: none;
        }
    }
    &__text{
        line-height: 1.5;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        margin-top: 32px;
        @include m-tablet-wide(){
            margin-top: 25px;
        }
        @include m-tablet(){
            margin-top: 20px;
        }
        @include m-phone(){
            margin-top: 24px;
            text-shadow: none;
        }
    }
    &__button {
        margin-top: 32px;
        @include m-tablet-wide(){
            margin-top: 25px;
        }
        @include m-tablet(){
            margin-top: 20px;
        }
        @include m-phone(){
            color: $color_text;
            border-color: $color_text;
            margin-top: 24px;
        }
    }
}