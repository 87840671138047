.scenari {
    position: relative;
    &__slider {
    }
    &__slide {
        position: relative;
        img{
            max-width: initial;
            width: 100%;
            min-height: 460px;
            object-fit: cover;
            @include m-phone(){
            }
        }
    }
    &__info {
        position: absolute;
        width: 100%;
        max-width: 1000px;
        text-align: center;
        top: 33px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 20px;
        z-index: 2;
        color: $color_white;
        @include m-tablet-wide(){
            max-width: 700px;
        }
        @include m-tablet(){
            max-width: 560px;
        }
        @include m-phone(){
            top: 40px;
        }
    }
    &__name {
        font-size: 32px;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        @include m-tablet-wide(){
            font-size: 28px;
        }
        @include m-tablet(){
            font-size: 26px;
        }
    }
    &__text {
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.5;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        @include m-tablet-wide(){
            font-size: 18px;
        }
        @include m-tablet(){
            font-size: 16px;
            line-height: 1.3;
            margin-top: 10px;
        }
    }
    &__controls {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 70px;
        z-index: 9;
        display: flex;
        justify-content: center;
        @include m-tablet-wide(){
            bottom: 30px;
        }
    }
    &__control {
        color: $color_white;
        text-align: center;
        margin: 0 15px;
        cursor: pointer;
        @include m-phone(){
            margin: 0 10px;
        }
        &.active,
        &:hover{
            .scenari{
                &__control-ico {
                    svg{
                        background-color: #6ab9ff;
                    }
                }
            }
        }
    }
    &__control-ico {
        svg{
            width: 64px;
            height: 64px;
            border-radius: 50%;
            transition: $trsn;
            @include m-tablet-wide(){
                width: 58px;
                height: 58px;
            }
            @include m-tablet(){
                width: 50px;
                height: 50px;
            }
        }
    }
    &__control-name {
        margin-top: 10px;
        line-height: 1.5;
        font-weight: normal;
        font-size: 18px;
        @include m-tablet(){
            font-size: 16px;
            line-height: 1.2;
        }
        @include m-phone(){
            font-size: 14px;
            line-height: 1.3;
            span{
                display: block;
            }
        }
        @include m-custom(350px){
            font-size: 13px;
        }
    }
}

.scenari {
}
.js-scenari-controls {
}
